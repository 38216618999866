import { useState, useEffect } from "react";
import metamask from "../images/metamask.png";
import FromToBox from "./FromToBox";
import Web3 from "web3";
import { ethers } from "ethers";
import abi from "../abi/transferToCosmos.json";

const contractABI = abi;

const MetaMaskFromToBox = ({
  title = "",
  chain,
  setFrom = () => {},
  from,
  to,
  setTo = () => {},
  loading,
  setLoading,
  balance,
  setBalance,
  estimateGas,
  setEstimateGas,
  connected,
  setConnected,
  account,
  setAccount,
  web3,
  setWeb3,
  disabled,
  alert,
  setAlert,
  inputDisabled = false,
  canFetch = false,
  setCanFetch = () => {},
}) => {
  useEffect(() => {
    if (connected && canFetch) {
      getBalance();
    }
  }, [connected, canFetch]);

  const connectWallet = async () => {
    if (typeof window.ethereum === "undefined") {
      alert("Please install MetaMask extension");
      return;
    }
    setLoading(true);
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts"
      });
      const chainId = await window.ethereum.request({ method: "eth_chainId" });
      const requiredChainId = process.env.REACT_APP_EVM_CHAIN_ID; // 150 in hexadecimal

      if (chainId !== requiredChainId) {
        try {
          // If the network with the required chainId is not added, it will throw an error
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: requiredChainId }]
          });
        } catch (switchError) {
          if (switchError.code === 4902) {
            // If the network is not added, add it
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: requiredChainId,
                  chainName: process.env.REACT_APP_EVM_CHAIN_NAME,
                  rpcUrls: [process.env.REACT_APP_EVM_RPC_URL],
                  nativeCurrency: {
                    name: "SIX",
                    symbol: "SIX",
                    decimals: 18
                  },
                  blockExplorerUrls: [
                    process.env.REACT_APP_EVM_EXPLORER_URL
                  ]
                }
              ]
            });
          } else {
            console.error("Failed to switch network:", switchError);
            setLoading(false);
            return;
          }
        }
      }

      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      setAccount(accounts[0]);
      setConnected(true);
      setAlert(null);
    } catch (error) {
      setLoading(false);
      console.error("Failed to connect wallet:", error);
    }
  };

  const disconnectWallet = () => {
    setLoading(true);
    setConnected(false);
    setBalance(null);
    setWeb3(null);
    setAccount(null);
    setLoading(false);
    setEstimateGas(null);
  };

  const getBalance = async () => {
    if (web3 && account) {
      try {
        const balanceWei = await web3.eth.getBalance(account);
        const balanceEth = web3.utils.fromWei(balanceWei, "ether");
        const contract = new web3.eth.Contract(
          contractABI,
          process.env.REACT_APP_CONTRACT_ADDRESS
        );
        const amountWei = ethers.utils.parseEther(String(0.01));
        const gasEstimation = await contract.methods
          .transferToCosmos(
            // mockup address for estimation gas
            "6x1k7cay8pzsm5ztv33c4ajsyxzphmzt67dcte5q2",
            amountWei
          )
          .estimateGas({ from: account });
        const realGas = web3.utils.fromWei(
          String(parseInt(gasEstimation * 1.3)),
          "ether"
        );
        setEstimateGas(realGas);
        setBalance(balanceEth);
        setCanFetch(false)
      } catch (error) {
        console.error("Failed to get balance:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <FromToBox
      title={title}
      connected={connected}
      connectIcon={metamask}
      onConnect={connectWallet}
      address={account}
      balance={balance}
      chain={chain}
      isLoading={loading}
      setFrom={setFrom}
      from={from}
      to={to}
      setTo={setTo}
      disabled={disabled}
      inputDisabled={inputDisabled}
      alert={alert}
      setAlert={setAlert}
      onDisconnect={disconnectWallet}
      estimateGas={estimateGas}
    />
  );
};

export default MetaMaskFromToBox;
